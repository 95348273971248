import { graphql } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import PageHeader from "../components/PageHeader";
import PageLayout from "../components/PageLayout";
import Separator from "../components/Separator";
import heroImage from "../images/hero-home.jpg";

const IndexPage = ({ data }) => {
  return (
    <PageLayout
      pageTitle="Matthew Goslett: Senior Staff Software Engineer @ Yoco, Cape Town, South Africa"
      metaDescription="Matthew Goslett is a software engineer & tech entrepreneur based in Cape Town, South Africa.
      He is a co-founder of Superbalist; and is currently employed as a Senior Staff Engineer at Yoco, an African
      technology company."
    >
      <div
        className="d-flex justify-content-center align-items-center mb-5"
        style={{
          position: "relative",
          width: "100%",
          height: "400px",
          backgroundImage: `url(${heroImage})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="d-flex flex-column flex-md-row align-items-center">
          <StaticImage
            src="../images/me-home.png"
            alt=""
            className="mr-md-5"
          />
          <div className="text-center text-md-left">
            <div
              style={{
                fontWeight: "bold",
                fontSize: "3rem",
                textTransform: "uppercase",
              }}
            >
              Hello, I'm Matthew
            </div>
            <div style={{ fontStyle: "italic", fontSize: "1.5rem" }}>
              A software engineer based in Amsterdam, Netherlands.
            </div>
          </div>
        </div>
      </div>
      <Container>
        <PageHeader header="About me" />
        <p>
          I'm a self-taught software engineer & tech entrepreneur, with a
          passion for architecting scalable, performant and highly available
          software solutions to service all functional areas of a business.
          <br />
          <br />
          I'm currently a Senior Staff Engineer at <a href="https://yoco.com">Yoco</a>, an African fintech offering
          small businesses and entrepreneurs a suite of payment centric services.
        </p>
        <p>
          I previously served as CTO of{" "}
          <a href="https://www.balfourgroup.com">Balfour Group</a>, a technology
          investment group with a diverse set of ventures, ranging from online
          education to digital currencies. Prior to my time at Balfour Group, I spent 7 years as CTO/Lead Developer and
          founding partner at <a href="https://superbalist.com">Superbalist.com</a>, South
          Africa's leading e-commerce fashion destination, now a subsidiary of{" "}
          <a href="https://takealot.com">takealot.com</a>.
        </p>
        <p>
          I have a passion for all things digital, love all languages (not equally), and enjoy designing and building
          full stack solutions. I lean towards the open source stack, and whilst Python is often my go-to language, I
          approach each project with an open mind and believe in using the right tool for the job.
        </p>
        <h3>My tech stack</h3>
        <Separator small />
        <ul>
          <li>Python</li>
          <li>Docker</li>
          <li>Kubernetes</li>
          <li>FastAPI</li>
          <li>Django</li>
          <li>React</li>
          <li>Typescript</li>
          <li>OSX</li>
          <li>PyCharm</li>
        </ul>
        <h3>My everything</h3>
        <Separator small />
        <Row>
          {data.allImageGalleryJson.edges.map(({ node }, index) => {
            return (
              <Col md="6" key={index}>
                <div className="img-border mb-3">
                  <GatsbyImage
                    image={getImage(node.image)}
                    alt=""
                    className="rounded"
                  />
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </PageLayout>
  );
};

export const query = graphql`
  query HomePageQuery {
    allImageGalleryJson {
      edges {
        node {
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
